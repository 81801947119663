import banner1 from './assets/banner1.png';
import banner2 from './assets/banner2.png';
import screen1 from './assets/08262021.png';
import screen2 from './assets/08282021.png';
import screen3 from './assets/08302021.png';

import './App.scss';

const screenshot = (gameDate, imageName) => {
  return (
    <div className='screenshot'>
      <span>{gameDate}</span>
      <img src={imageName} alt='screenshot'/>
    </div>
    )
}


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={banner1} className='banner' alt='famlee band'/>
        <img src={banner2} className='banner' alt='you suck I rule flavortext'/>
        <p>
          {screenshot('08/30/2021', screen3)}
          {screenshot('08/28/2021', screen2)}
          {screenshot('08/26/2021', screen1)}
        </p>
        
      </header>
    </div>
  );
}

export default App;
